exports.components = {
  "component---src-templates-day-js": () => import("./../../../src/templates/day.js" /* webpackChunkName: "component---src-templates-day-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-month-js": () => import("./../../../src/templates/month.js" /* webpackChunkName: "component---src-templates-month-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */),
  "component---src-templates-year-js": () => import("./../../../src/templates/year.js" /* webpackChunkName: "component---src-templates-year-js" */)
}

